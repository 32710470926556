import { ICommandBarItem, ITableColumn } from "@interfaces/common.interfaces";
import CommandBar from "../../command-bar/command-bar";
import { PropsWithChildren, cloneElement, useContext, useEffect, useMemo, useState } from "react";
import {
    InfoRegular,
    FilterRegular,
    ArrowExportRegular,
    ArrowExportUpRegular,
    ArrowDownloadRegular,
    ReplayRegular,
    ArrowRepeat1Regular,
    ArrowRepeatAllRegular,
    ArrowImportRegular
} from "@fluentui/react-icons";
import { useLocation, useSearchParams, useParams } from "react-router-dom";
import { IReportSectionProps } from "./report-section.props";
import { IReport, IReportRequest } from "@interfaces/reports/reports-common.interfaces";
import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";
import { usePPA } from "context/ppa-context";
import { TeamsFxContext } from "context/teams-context";
import { TableColumnSizingOptions, makeStyles, shorthands } from "@fluentui/react-components";
import { ExportReportButton } from "components/command-bar/export-report-button/export-report-button";
import { ReportResultsToggle } from "components/command-bar/report-results-toggle/report-results-toggle";
import ReportResults from "../report-results/report-results";
import ReportInput from "../report-input/report-input";
import { ReportRequestsButton } from "components/command-bar/report-requests-button/report-requests-button";
import ReportActionInput from "../report-action-input/report-action-input";
import { IActionDefinition, IActionRequest } from "@interfaces/actions/actions-common.interfaces";
import MessagesBar from "@components/common/messages-bar/messages-bar";
import ReportActionRequestsButton from "components/command-bar/report-action-requests-button/report-action-requests-button";
import ReportResultsMessageBar from "../report-results-message-bar/report-results-message-bar";
import ScrollPanel from "components/layout/scroll-panel/scroll-panel";
import { CallbackButton } from "components/command-bar/callback-button/callback-button";

const defaultCommandBarItems: Array<ICommandBarItem> = [
    {
        id: 'openInput',
        placement: 'right',
        order: 3,
    },
    // {
    //     id: 'openActionResultsPanel',
    //     placement: 'right',
    //     order: 2,
    //     //component: <ReportActionRequestsButton />
    // },
    {
        id: 'resultsToggle',
        placement: 'right',
        order: 1,
    },
    // {
    //     id: 'filterResults',
    //     placement: 'right',
    //     order: 1,
    // },
    {
        id: 'export',
        placement: 'left',
        order: 1
    },
    {
        id: 'reportRequests',
        placement: 'left',
        order: 2,
        component: <ReportRequestsButton />
    }
];

//style={{ flexGrow: 1, display: 'flex', overflow: 'hidden', position: 'relative' }}

const layoutStyles = makeStyles({
    mainWrapper: {
        //...shorthands.borderWidth('1px', '0'),
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        //...shorthands.overflow('hidden'),
        position: 'relative',
        //backgroundColor: '#ddd'
    }
});

export default function ReportSection<TI, TD>(props: PropsWithChildren<IReportSectionProps<TI, TD>>) {
    const styles = layoutStyles();
    const location = useLocation();
    const { getApiClient } = usePPA();

    const [isInputOpen, setIsInputOpen] = useState<boolean>(false);
    const [isActionInputOpen, setIsActionInputOpen] = useState<boolean>(false);

    const [status, setStatus] = useState<number>(-1); // TODO: Enum??
    const [percentageComplete, setPercentageComplete] = useState<number>(0); // Maybe something else...
    const [actionRequestCount, setActionRequestCount] = useState<number>(0);
    const [requestId, setRequestId] = useState<string>();
    const [requested, setRequested] = useState<string>();

    const [checkCount, setCheckCount] = useState<number>(0);

    //const [reportData, setReportData] = useState<Array<T>>([]);
    const [report, setReport] = useState<IReport<TI, TD>>();

    const [resultsView, setResultsView] = useState<string>('items'); // TODO: a type.

    // 
    const params = useParams();
    const [loadingReport, setLoadingReport] = useState<boolean>(false);


    //
    const [selectedItems, setSelectedItems] = useState<Array<TI>>([]);

    //
    const [selectedAction, setSelectedAction] = useState<any>();

    //
    //const [gridColumns, setGridColumns] = useState<ITableColumn<T>>();
    //const [exportColumns, setExportColumns] = useState<ITableColumn<T>>();

    const onCloseInput = () => {
        setIsInputOpen(false);
    }

    const onCloseActionInput = () => {
        setIsActionInputOpen(false);
    }

    const gridColumns = useMemo(() => {
        return props.columns.filter(c => c.showInGrid === true);
    }, [props.columns]);

    const exportColumns = useMemo(() => {
        return props.columns.filter(c => c.showInExport === true);
    }, [props.columns]);


    function onActionButtonClick<TA>(actionDefinition: IActionDefinition<TA>): void {
        console.log(`ReportSection -> onActionButtonClick2 ->`, actionDefinition);

        //
        setSelectedAction(actionDefinition);

        // Open the ActionInput panel.
        setIsActionInputOpen(true);
    }

    function onActionResultsClick(): void {
        console.log(`ReportSection -> onActionResultsClick ->`);
    }

    function toggleInputOpen(): void {
        setIsInputOpen(!isInputOpen);
    }

    const commandBarItems = useMemo(() => {
        //console.log(`ReportSection -> useMemo -> commandbar -> `);
        var buttons = defaultCommandBarItems.map(item => {
            //console.log(`ReportSection -> useMemo -> commandbar -> id -> `, item.id);
            switch (item.id) {
                case 'openInput':
                    return {
                        ...item,
                        component: <CallbackButton key={item.id} icon={<ArrowImportRegular onClick={toggleInputOpen} />} />
                    }
                case 'openActionResultsPanel':
                    return {
                        ...item,
                        component: <ReportActionRequestsButton key={item.id} onClick={onActionResultsClick} />
                    }
                case 'resultsToggle':
                    return {
                        ...item,
                        component: props.summaryComponent ?
                            <ReportResultsToggle disabled={!report} view={resultsView} onViewChange={(view: string) => { setResultsView(view) }} />
                            : undefined
                    }
                case 'export':
                    return {
                        ...item,
                        component: <ExportReportButton key={item.id} columns={exportColumns} items={report?.items} defaultFilenamePrefix={props.defaultExportFilenamePrefix} />
                    }
                // case 'reportRequests':
                //     return {
                //         ...item,
                //         component: <ReportRequestsButton />
                //     }
                default:
                    return item;
            }
        });

        if (props.buttons) {
            var actionButtons = props.buttons.map<ICommandBarItem>((button): ICommandBarItem => {
                return {
                    id: '1',
                    placement: 'left',
                    order: 10,
                    component: cloneElement(button.component, { selectedItems: selectedItems, onClick: onActionButtonClick })
                }
            });

            buttons = buttons.concat(actionButtons);
        }

        return buttons;
    }, [location.pathname, isInputOpen, report, exportColumns, resultsView, selectedItems]);


    // // Create the api client for each of the api calls.
    // const apiClient = useMemo(() => {
    //     //console.log(`ReportSection -> useMemo -> createApiClient -> `);
    //     return createApiClient(
    //         ppa.appInstanceApiUrl || "",
    //         new BearerTokenAuthProvider(async () => (await teamsUserCredential?.getToken(""))!.token)
    //     );
    // }, [ppa.appInstanceApiUrl]);

    // Initialise...
    useEffect(() => {
        if (params['id']) {
            setLoadingReport(true);
            getRequestStatus(params['id']).then((request: IReportRequest) => {
                setRequested(request.requested);
                setRequestId(request.requestId);
                if (request.status !== 3) {
                    setLoadingReport(false);
                }
            });
        }
        else {
            setIsInputOpen(true);
        }
    }, [params]);

    //
    useEffect(() => {
        //console.log(`ReportSection -> useEffect -> `, requestId, status, checkCount);
        var timerId: NodeJS.Timeout;
        if (requestId && status > -1) {
            // TODO: timedOut -> use requested and add xx seconds to see if that's < now.
            const timedOut = false;
            if (status !== 3 && status !== 4) {
                if (timedOut) {
                    // TODO: Something...
                }
                else {
                    timerId = setTimeout(() => {
                        getRequestStatus(requestId);
                    }, 1000);
                }
            }
            else if (status === 3) {
                //console.log(`ReportSection -> useEffect -> GET THE REPORT -> `);

                // TODO: This check really needs to see if the report has been retrieved and stop...
                getReport(requestId);
                //
                //getReportActionRequests(requestId);
            }
        }
        return () => clearTimeout(timerId);
    }, [requestId, status, checkCount]);

    const requestReport = async (): Promise<IReportRequest> => {
        try {
            //console.log(`ReportSection -> requestReport -> `);
            clearResults();
            const response = await getApiClient().post<IReportRequest>(props.reportUrl, props.model);
            setRequested(response.data.requested);
            setRequestId(response.data.requestId);
            setStatus(response.data.status);
            return response.data;
        }
        catch (ex: unknown) {
            throw ex; // TODO: Handle the error, don't throw as this is the top level!!
        }
    }

    const getRequestStatus = async (requestId: string): Promise<IReportRequest> => {
        try {
            //console.log(`ReportSection -> getRequestStatus -> `);
            const response = await getApiClient().get<IReportRequest>(`/api/report-requests/${requestId}`);
            //console.log(`ReportSection -> getRequestStatus -> data -> `, response.data);

            setStatus(response.data.status);
            setPercentageComplete(response.data.percentageComplete);
            setActionRequestCount(response.data.actionRequestCount);
            setCheckCount(checkCount + 1);
            return response.data;
        }
        catch (ex: unknown) {
            throw ex; // TODO: Handle the error, don't throw as this is the top level!!
        }
    }

    const getReport = async (requestId: string): Promise<IReport<TI, TD>> => {
        try {
            setLoadingReport(true);
            const response = await getApiClient().get<IReport<TI, TD>>(`${props.reportUrl}/${requestId}`);
            setReport(response.data);
            setLoadingReport(false);
            return response.data;
        }
        catch (ex: unknown) {
            setLoadingReport(false);
            throw ex; // TODO: Handle the error, don't throw as this is the top level!!
        }
    }

    const onRequestCallback = (actionRequest: IActionRequest) => {
        console.log(`onRequestCallback ->`, actionRequest);
        // This is temp for now.
        // What it should do is when the request status is loaded and it has one or more actionRequestCount:
        // - Load the action requests and with them the results.
        // - That will need to be another type TA that gets fed into ReportResults
        // - Some sort of column that decides if it should render an action icon...
        // Maybe another panel that is ActionResults that shows the timeline.
        if (requestId) {
            getRequestStatus(requestId);
        }
    }


    // const getReportActionRequests = async (requestId: string): Promise<any> => {
    //     try {
    //         console.log(`ReportSection -> getReportActionRequests ->`, requestId);
    //         // /api/action-requests
    //         // /api/report-requests/[requestId]/action-requests
    //         const response = await apiClient.get<any>(`${props.reportUrl}/${requestId}`); //IReport<TI, TD>
    //     }
    //     catch (ex: unknown) {
    //         //
    //         throw ex;
    //     }
    // }

    // const requestAction = async (): Promise<any> => { //IActionRequest
    //     try {
    //         console.log(`ReportSection -> requestAction -> `);
    //         //clearResults();
    //         //const response = await apiClient.post<IReportRequest>(props.reportUrl, props.model);
    //         //setRequested(response.data.requested);
    //         //setRequestId(response.data.requestId);
    //         //setStatus(response.data.status);
    //         //return response.data;
    //     }
    //     catch (ex: unknown) {
    //         throw ex; // TODO: Handle the error, don't throw as this is the top level!!
    //     }
    // }

    const clearResults = () => {
        setCheckCount(0);
        setStatus(-1);
        setPercentageComplete(0);
        setActionRequestCount(0);
        setLoadingReport(false);
        setRequestId(undefined);
        setRequested(undefined);
        setReport(undefined);
        setSelectedItems([]);
    }

    // TODO: This could be a util method rather than in here...
    const getColumnOptions = (columns: Array<ITableColumn<TI>>): TableColumnSizingOptions => {
        var options: TableColumnSizingOptions = {};
        columns.forEach((column) => {
            options[column.key] = {
                idealWidth: column.idealWidth,
                minWidth: column.minWidth,
                defaultWidth: column.defaultWidth,
                //padding: 0
            }
        });
        return options;
    }

    // TODO: Pass the state to the input children by either cloning OR context api...
    // That will allow the components to be set readonly while submittings, etc...
    // https://stackabuse.com/bytes/passing-props-to-this-props-children-in-reactjs/

    // TODO: ReportActionsResults panel.
    // Shows all the results for all the actions requested for the report.
    // Show them in a timeline... How?
    // Use the report results as the details
    // The ActionResult is the cut down version from the DB.
    // We don't want to get all the blob results back so use the cut down results with the report results...

    return (
        <ScrollPanel
            fixed={<>
                <CommandBar items={commandBarItems} />
                <ReportResultsMessageBar
                    actionRequestCount={actionRequestCount}
                    onViewActionResultsClick={onActionResultsClick} />
            </>}
            scroll={<>
                <ReportResults
                    columns={gridColumns}
                    columnOptions={getColumnOptions(gridColumns)}
                    requestStatus={status}
                    percentageComplete={percentageComplete}
                    loading={loadingReport}
                    report={report}
                    summaryComponent={props.summaryComponent}
                    noItemsMessage={props.noItemsMessage}
                    view={resultsView}
                    selectionType={props.selectionType}
                    onSelectionChanged={(items: Array<TI>) => { setSelectedItems(items) }} />
                <ReportInput
                    isOpen={isInputOpen}
                    onClose={onCloseInput}
                    onSubmit={requestReport}
                    canSubmit={props.isModelValid}>
                    {props.children}
                </ReportInput>
                <ReportActionInput
                    isOpen={isActionInputOpen}
                    onClose={onCloseActionInput}
                    actionDefinition={selectedAction}
                    reportRequestId={report?.requestId}
                    onRequestCallback={onRequestCallback} />
            </>} />
    );
}