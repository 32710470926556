import { forwardRef } from "react";
import { Button, ForwardRefComponent, MenuItem, makeStyles } from "@fluentui/react-components";
import { ILocaleStrings } from "loc/locale.interfaces";
import { useL10n } from "context/l10n-context";
import { ICallbackButtonProps } from "./callback-button.props";

const layoutStyles = makeStyles({

});

export const CallbackButton: ForwardRefComponent<ICallbackButtonProps> = forwardRef((props, ref) => {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    const onClick = () => {
        if (props.onClick)
            props.onClick();
    }

    if (props.isOverflowingMenu)
        return (
            <MenuItem
                icon={props.icon}
                disabled={props.disabled}
                onClick={onClick}>{props.label ? t(props.label) : ''}</MenuItem>
        );

    return (
        <Button
            ref={ref}
            icon={props.icon}
            disabled={props.disabled}
            appearance="subtle"
            onClick={onClick} >{props.label ? t(props.label) : ''}</Button>
    );
});