import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { IBreadcrumbProps } from "./breadcrumb.props";
//import navItemsV2 from "@lib/nav-items-v2";
//import navItemsV2 from "../../../lib/nav-items-v2";
import { Button, Link, Overflow, OverflowDivider, OverflowItem, makeStyles, mergeClasses, shorthands, tokens } from "@fluentui/react-components";
import {
  ChevronRightRegular,
  HomeRegular
} from "@fluentui/react-icons";
import navItems from "lib/nav-items";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";
import { IBreadcrumbItem } from "@interfaces/common.interfaces";


const layoutStyles = makeStyles({
  mainWrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto auto 1fr', // The last one here doesn't have a col but gives it the space.
  },
  itemWrapper: {
    display: 'flex',
    alignItems: 'center',
    ...shorthands.overflow('hidden')
  },
  divider: {
  },
  linkItem: {
    minWidth: 0,
    ...shorthands.padding('0', '0.25em'),
    fontWeight: 'normal',
  },
  activeItem: {
    ...shorthands.padding('0', '0.25em'),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ...shorthands.overflow('hidden'),
    fontWeight: tokens.fontWeightSemibold
  },
  textWrapper: {
    ...shorthands.overflow('hidden'),
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
  }
});

export default function Breadcrumb(props: IBreadcrumbProps) {
  const styles = layoutStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [items, setItems] = useState<Array<IBreadcrumbItem>>([]);
  const { t } = useL10n<ILocaleStrings>();

  useEffect(() => {
    let path = ``;
    let items: Array<IBreadcrumbItem> = [];
    var routes = location.pathname.split('/').filter(p => p.length > 0);

    routes.forEach(route => {
      path += `/${route}`;
      let match = navItems.filter(i => i.route === path)[0];
      if (match) {
        items.push({ id: match.id, url: match.route, label: match.label, current: match.route === location.pathname });
      }
    });

    setItems(items);
  }, [location.pathname]);

  return (
    <div className={styles.mainWrapper}>
      <div>
        <Button
          appearance="transparent"
          icon={<HomeRegular />}
          onClick={() => navigate('/')} />
      </div>
      {items.map((item, idx) => (
        <div key={idx} className={styles.itemWrapper}>
          <span className={styles.divider}>
            <ChevronRightRegular />
          </span>
          {!item.current &&
            <Button
              className={styles.linkItem}
              appearance="transparent"
              onClick={() => navigate(item.url)}>
              <span className={styles.textWrapper}>{t(item.label)}</span>
            </Button>}
          {item.current &&
            <span className={styles.activeItem}>{t(item.label)}</span>}
        </div>)
      )}
    </div>
  );
}
