import { PropsWithChildren } from "react";
import { Button, MessageBar, MessageBarActions, MessageBarBody, MessageBarGroup, MessageBarTitle, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import {
    InfoRegular,
    DismissRegular
} from "@fluentui/react-icons";
import { IMessagesBarProps } from "./messages-bar.props";
import { ILocaleStrings } from "loc/locale.interfaces";
import { useL10n } from "context/l10n-context";

const layoutStyles = makeStyles({
    mainWrapper: {
    },
    bodyText: {
        textWrap: 'auto',
        ...shorthands.padding('0.5em', 0)
    }
});

export default function MessagesBar(props: IMessagesBarProps) {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    return (
        <div className={styles.mainWrapper}>
            <MessageBarGroup>
                <MessageBar icon={props.icon || <InfoRegular />}>
                    <MessageBarBody className={styles.bodyText}>
                        {props.title && <MessageBarTitle>{props.title}</MessageBarTitle>}
                        {props.message}
                    </MessageBarBody>
                    <MessageBarActions
                        containerAction={props.onDismiss ?
                            <Button
                                aria-label={t('dismiss')}
                                appearance="transparent"
                                onClick={props.onDismiss}
                                icon={<DismissRegular />} /> : undefined}>
                        {props.actions?.map((action, idx) => (
                            <Button key={idx} onClick={action.onClick}>{action.label}</Button>
                        ))}
                    </MessageBarActions>
                </MessageBar>
            </MessageBarGroup>
        </div>
    );
}