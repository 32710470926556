import { PropsWithChildren, createElement, forwardRef, useEffect, useState } from "react";
import {
    ArrowDownloadRegular,
    PersonDeleteRegular
} from "@fluentui/react-icons";
import { Button, ForwardRefComponent, MenuItem, ToolbarButton, ToolbarDivider, ToolbarGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { IOrphanedUsersInTenantReportItem } from "@interfaces/reports/orphaned-object-reports.interfaces";
import { IRemoveOrphanedUsersActionButtonProps } from "./remove-orphaned-users-action-button.props";
import { IRemoveOrphanedUsersSelection } from "@interfaces/actions/orphaned-user-actions.interfaces";
//import { IActionDefinition } from "@components/reports-common/report-section/report-section.props";
import RemoveOrphanedUsersActionForm from "./remove-orphaned-users-action-form";
import { IActionDefinition } from "@interfaces/actions/actions-common.interfaces";
import RemoveOrphanedUsersActionResult from "./remove-orphaned-users-action-result";
import { getActionFormComponent, getActionResultComponent } from "lib/actions";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";

const layoutStyles = makeStyles({
    mainWrapper: {
    },
    hidden: {
        display: 'none'
    }
});

export const RemoveOrphanedUsersActionButton: ForwardRefComponent<IRemoveOrphanedUsersActionButtonProps> = forwardRef((props, ref) => {
    const styles = layoutStyles();
    const { t } = useL10n<ILocaleStrings>();

    const [model, setModel] = useState<IRemoveOrphanedUsersSelection | undefined>();

    const [className, setClassName] = useState<string>(styles.hidden);

    const onClick = () => {
        console.log(`RemoveOrphanedUsersActionButton -> onClick ->`);

        // Construct the action definition in here to return...
        // const actionDefinition: IActionDefinition<IRemoveOrphanedUsersSelection> = {
        //     model: model,
        //     form: <RemoveOrphanedUsersActionForm />,
        //     result: <RemoveOrphanedUsersActionResult />
        // };

        const actionDefinition: IActionDefinition<IRemoveOrphanedUsersSelection> = {
            actionType: 1,
            model: model,
            form: getActionFormComponent(1),
            result: getActionResultComponent(1)
        };


        if (props.onClick) {
            props.onClick(actionDefinition);
        }
    }

    useEffect(() => {
        console.log(`RemoveOrphanedUsersActionButton -> useEffect -> selectedItems ->`, props.selectedItems);

        if (props.selectedItems && props.selectedItems.length == 1) {
            const item = props.selectedItems[0];
            setModel({
                site: {
                    url: item.workspaceUrl,
                    name: item.workspaceName
                },
                user: {
                    upn: item.upn,
                    name: item.displayName
                }
            });
        }
        else {
            // Clear the model
            setModel(undefined);
        }

        //
        if (props.selectedItems && props.selectedItems.length > 0) {
            setClassName('');
        }
        else {
            setClassName(styles.hidden);
        }
    }, [props.selectedItems]);

    if (props.isOverflowingMenu)
        if (props.selectedItems && props.selectedItems.length > 0)
            return (
                <MenuItem
                    icon={<PersonDeleteRegular />}
                    disabled={props.selectedItems && props.selectedItems.length > 1}
                    onClick={onClick}>{t('remove')}</MenuItem>
            );
        else
            return <></>;

    return (
        <Button
            ref={ref}
            className={className}
            icon={<PersonDeleteRegular />}
            disabled={props.selectedItems && props.selectedItems.length > 1}
            appearance="subtle"
            onClick={onClick}>{t('remove')}</Button>
    );
});