import {
    Overflow,
    OverflowItem,
    Subtitle1,
    TabList,
    Tab,
    Text,
    Title3,
    makeStyles,
    shorthands,
    tokens,
    Button,
    Body1Strong,
    Image,
    MessageBar,
    MessageBarBody,
    MessageBarTitle,
    Divider,
    Menu,
    MenuTrigger,
    MenuPopover,
    MenuList,
    MenuItem,
    Link
} from "@fluentui/react-components";
import {
    Box20Regular,
    ChatWarningRegular,
    SettingsRegular,
    NavigationRegular,
    HomeRegular,
    DocumentSearchRegular,
    AppsListDetailRegular,
    QuestionRegular,
    TaskListLtrRegular,
    ChevronRightRegular
} from "@fluentui/react-icons";
import { IPagelayoutProps } from "./page-layout.props";
import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { IMenuItem } from "@interfaces/common.interfaces";
import navItems from "lib/nav-items";
import { getTopLevelMenuItems } from "lib/utils";
import { usePPA } from "context/ppa-context";
import ScreenPlaceholder from "components/common/screen-placeholder/screen-placeholder";
import Disabled from "components/initialising-ppa/disabled";
import LicenceExpired from "components/initialising-ppa/licence-expired";
import Breadcrumb from "components/navigation/breadcrumb/breadcrumb";
import { useL10n } from "context/l10n-context";
import { ILocaleStrings } from "loc/locale.interfaces";

const layoutStyles = makeStyles({
    mainWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'min-content 1fr',
        height: '100vh',
    },
    header: {
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: tokens.colorNeutralStrokeSubtle,
        ...shorthands.padding("0em", "1em"),
        display: 'grid',
        gridTemplateColumns: 'min-content 1fr min-content'
    },
    headerDivider: {
        width: "1px",
        height: "75%",
        backgroundColor: tokens.colorNeutralStrokeSubtle,
        ...shorthands.margin(0, "0.5em"),
    },
    menu: {
        display: 'flex',
        alignItems: 'center',
    },
    messageBar: {
        ...shorthands.margin("1em", "2em")
    },
    content: {
        ...shorthands.overflow('auto'),
        position: 'relative'
    }
});

export default function PageLayout(props: PropsWithChildren<IPagelayoutProps>) {

    // TODO: Separate the header out??
    const { hasAccess, isSignupComplete, ssoError, disabled, licenceExpired } = usePPA();

    const { t } = useL10n<ILocaleStrings>();

    const navigate = useNavigate();
    const location = useLocation();
    const styles = layoutStyles();

    //const [menuItems, setMenuItems] = useState<Array<IMenuItem>>(navItems.map(item => { return item as IMenuItem }));
    //const [menuItems, setMenuItems] = useState<Array<IMenuItem>>(navItems.filter(i => !i.parentId).map(item => { return item as IMenuItem }));
    const [menuItems, setMenuItems] = useState<Array<IMenuItem>>(getTopLevelMenuItems());

    // TODO: Get nav items from the main context

    // TEMP:
    //const nameof = <T>(name: Extract<keyof T, string>): string => name;
    //const nameof = <ILocaleStrings>(name: Extract<keyof ILocaleStrings, string>): string => name;
    //const nameof = (name: Extract<keyof ILocaleStrings, string>): string => name;

    useEffect(() => {
        var baseRoute = location.pathname.split('/').filter(p => p.length > 0)[0];
        const updated = menuItems.map(item => {
            return {
                ...item,
                isActive: item.route === `/${baseRoute}`
            }
        });
        setMenuItems(updated);
        console.log(`path -> `, location.pathname);
    }, [location.pathname]);

    return (
        <main className={styles.mainWrapper}>
            <div>
                {hasAccess && isSignupComplete &&
                    <div className={styles.header}>
                        <div className={styles.menu}>
                            <Menu>
                                <MenuTrigger>
                                    <Button
                                        appearance="transparent"
                                        icon={<NavigationRegular />} />
                                </MenuTrigger>
                                <MenuPopover>
                                    <MenuList>
                                        <MenuItem
                                            icon={<DocumentSearchRegular />}
                                            onClick={() => navigate('/reports')}>{t("ppaReportsTitle")}</MenuItem>
                                        <MenuItem
                                            icon={<AppsListDetailRegular />}
                                            onClick={() => navigate('/report-requests')}>{t("ppaReportRequestsTitle")}</MenuItem>
                                        <MenuItem
                                            icon={<TaskListLtrRegular />}
                                            onClick={() => navigate('/action-requests')}>{t("ppaActionRequestsTitle")}</MenuItem>
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                            <div className={styles.headerDivider}></div>
                        </div>
                        <Breadcrumb />
                        <div>
                            {/* <Button appearance="transparent" icon={<QuestionRegular />} /> */}
                        </div>
                    </div>
                }
                {ssoError &&
                    <div className={styles.messageBar}>
                        <MessageBar intent="error">
                            <MessageBarBody>
                                <MessageBarTitle>{ssoError}</MessageBarTitle>
                            </MessageBarBody>
                        </MessageBar>
                    </div>}
            </div>
            <div className={styles.content}>
                {disabled && <Disabled />}
                {!disabled && licenceExpired && <LicenceExpired />}
                {hasAccess && props.children}
            </div>
        </main>
    );
}